<template>
  <div>
    <tagby-filter
      :categoryOptions="categoryOptions"
      :initial-search-params="initialSearchParams"
      @search="search"
    >
      <template #shortcut>
        <shortcuts
          :waitCount="waitCount"
          @reload="shortcutSearch"
        />
      </template>
    </tagby-filter>
    <tagby-list
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(idx)="data">
        <BLink :to="resolveLinkTo(data.item)">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(thumbnail_img_url)="data">
        <BImg
          :src="data.item.thumbnail_img_url"
          width="60"
          height="60"
          rounded
        />
      </template>

      <template #cell(vendor)="data">
        <div
          v-if="data.item.vendor"
          style="width: 100px"
        >
          <div>
            {{ data.item.vendor.company_name }}
          </div>
          <div>
            (
            <span>
              {{ data.item.vendor.idx }}
            </span>
            <span
              v-if="data.item.vendor.legacy_vendor_no"
              class="text-danger"
            >
              / {{ data.item.vendor.legacy_vendor_no }}
            </span>
            )
          </div>
        </div>
      </template>

      <template #cell(name)="data">
        <div style="width: 180px">
          <BLink :to="resolveLinkTo(data.item)">
            {{ data.value }}
          </BLink>
          <div v-if="data.item.brand_name">
            ({{ data.item.brand_name }})
          </div>
        </div>
      </template>

      <template #cell(product_no)="data">
        <div style="width: 200px;">
          <div>
            {{ data.item.product_no }}
          </div>
          <div v-if="data.item.tagby_product_no">
            ({{ data.item.tagby_product_no }})
          </div>
        </div>
      </template>

      <template #cell(category)="data">
        <div style="width: 100px">
          {{ resolveCategory(data.item.category) }}
        </div>
      </template>

      <template #cell(sell_type)="data">
        <div style="width: 50px">
          {{ resolveSellType(data.item.sell_type) }}
        </div>
      </template>

      <template #cell(reason)="data">
        <div style="width: 50px">
          {{ resolveReason(data.value) }}
        </div>
      </template>

      <template #cell(state)="data">
        <div
          style="width: 80px"
          :class="data.value === 'WAIT' ? 'text-warning' : null"
        >
          {{ resolveState(data.value) }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <div style="width: 90px;">
          {{ resolveDateTime(data.value) }}
        </div>
      </template>

      <template #cell(restocked_at)="data">
        <div style="width: 90px;">
          {{ resolveDate(data.value) }}
        </div>
      </template>
    </tagby-list>
  </div>
</template>

<script>
import {
  BLink,
  BImg,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'
import resolveValue from '@/utils/resolveValue'
import Shortcuts from './components/Shortcuts.vue'

export default {
  components: {
    BLink,
    BImg,
    TagbyFilter,
    TagbyList,
    Shortcuts,
  },
  setup() {
    const {
      resolveDate,
      resolveDateTime,
      resolveMoney,
    } = resolveValue()
    const resolveCategory = category => [category?.class_1, category?.class_2, category?.class_3].filter(e => e != null).join(',')
    const resolveSellType = value => {
      if (value === 'ALWAYS') return '상시형'
      if (value === 'RECRUITMENT') return '공모형'
      return ''
    }
    const resolveState = value => {
      if (value === 'WAIT') return '대기중'
      if (value === 'ACCEPT') return '승인완료'
      if (value === 'REJECT') return '거부완료'
      if (value === 'INACTIVE') return '삭제'
      return ''
    }
    const resolveReason = value => {
      if (value === 'REGISTER') return '생성요청'
      if (value === 'UPDATE') return '수정요청'
      if (value === 'DELETE') return '삭제요청'
      return ''
    }
    const resolveLinkTo = item => {
      if (item.reason === 'UPDATE') {
        return {
          name: 'commerce-product-update-detail',
          params: { idx: item.idx },
        }
      }
      return {
        name: 'commerce-product-update-others-detail',
        params: { idx: item.idx },
      }
    }
    return {
      resolveDate,
      resolveDateTime,
      resolveMoney,
      resolveCategory,
      resolveSellType,
      resolveState,
      resolveReason,
      resolveLinkTo,
      ...viewModel(),
    }
  },
}
</script>
