<template>
  <BRow class="w-100 mb-1">
    <BCol class="d-flex">
      <BBadge
        pill
        variant="warning"
        class="mr-1"
        href="#"
        @click="$emit('reload', {state: ['WAIT']})"
      >
        대기중:{{ waitCount }}
      </BBadge>
    </BCol>
  </BRow>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
  },
  emits: [
    'reload',
  ],
  props: {
    waitCount: {
      type: Number,
      default: 0,
    },
  },
}
</script>
